
import { Context, DiagramTool } from '@icepanel/app-canvas'
import { Diagram, DiagramConnection, DiagramContent, DiagramObjectShape, DiagramObjectType, Flow, FlowStep, ModelConnection, ModelConnectionTechnology, ModelObject, ModelObjectTechnology, modelStatuses, Task } from '@icepanel/platform-api-client'
import isEqual from 'lodash/isEqual'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Animation from '@/components/animation.vue'
import Tabs, { ITab } from '@/components/tabs.vue'
import * as sort from '@/helpers/sort'
import { iconUrlForTheme } from '@/helpers/theme'
import AccuracyHelpDialog from '@/modules/accuracy/components/help-dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import CatalogTechnologyMenu from '@/modules/catalog/components/technology/menu.vue'
import { CatalogModule } from '@/modules/catalog/store'
import CommentThreadDialog from '@/modules/comment/components/comment-dialog/thread.vue'
import { CommentModule } from '@/modules/comment/store'
import DiagramCameraControls from '@/modules/diagram/components/camera-controls.vue'
import DiagramCanvas from '@/modules/diagram/components/canvas.vue'
import DiagramDeleteDialog from '@/modules/diagram/components/delete-dialog.vue'
import DiagramMenu from '@/modules/diagram/components/diagram-menu.vue'
import DiagramGroupDeleteDialog from '@/modules/diagram/components/group/delete-dialog.vue'
import DiagramInfo from '@/modules/diagram/components/info.vue'
import DiagramLostAlert from '@/modules/diagram/components/lost-alert.vue'
import DiagramProposedMergeDialog from '@/modules/diagram/components/proposed-merge-dialog.vue'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import FlowCancelButton from '@/modules/flow/components/cancel-button.vue'
import FlowDeleteDialog from '@/modules/flow/components/delete-dialog.vue'
import FlowContextMenu from '@/modules/flow/components/flow-context-menu/index.vue'
import FlowName from '@/modules/flow/components/name.vue'
import FlowPathPreview from '@/modules/flow/components/path/preview.vue'
import FlowPicker from '@/modules/flow/components/picker.vue'
import FlowStepIndicator from '@/modules/flow/components/step/indicator.vue'
import FlowStepList from '@/modules/flow/components/step/list.vue'
import FlowStepPreview from '@/modules/flow/components/step/preview.vue'
import FlowStepControlBack from '@/modules/flow/components/step-control/back.vue'
import FlowStepControlNext from '@/modules/flow/components/step-control/next.vue'
import FlowStepControlRestart from '@/modules/flow/components/step-control/restart.vue'
import FlowSubflowBack from '@/modules/flow/components/subflow/back.vue'
import FlowSubflow from '@/modules/flow/components/subflow/index.vue'
import { FlowModule } from '@/modules/flow/store'
import HistoryCompact from '@/modules/history/components/history-compact.vue'
import landscapeAnalyticsGroup from '@/modules/landscape/helpers/analytics-group'
import { LandscapeModule } from '@/modules/landscape/store'
import ModelActionsMenu from '@/modules/model/components/actions-menu.vue'
import ModelConnectionChange from '@/modules/model/components/connections/connection-change.vue'
import ModelConnectionDeleteDialog from '@/modules/model/components/connections/connection-delete-dialog.vue'
import ModelConnectionDirection from '@/modules/model/components/connections/connection-direction.vue'
import ModelConnectionFlipDialog from '@/modules/model/components/connections/connection-flip-dialog.vue'
import ModelConnectionLabelPosition from '@/modules/model/components/connections/connection-label-position.vue'
import ModelConnectionLower from '@/modules/model/components/connections/connection-lower.vue'
import ModelConnectionReassignDialog from '@/modules/model/components/connections/connection-reassign-dialog.vue'
import ModelConnectionReceiver from '@/modules/model/components/connections/connection-receiver.vue'
import ModelConnectionSender from '@/modules/model/components/connections/connection-sender.vue'
import ModelConnectionShape from '@/modules/model/components/connections/connection-shape.vue'
import ModelInDiagrams from '@/modules/model/components/in-diagrams.vue'
import ModelInFlows from '@/modules/model/components/in-flows.vue'
import ModelObjectDependenciesList from '@/modules/model/components/object-dependencies-list/index.vue'
import ModelObjectGroups from '@/modules/model/components/object-group/index.vue'
import ModelObjectLinksList from '@/modules/model/components/objects/links-list/index.vue'
import ModelObjectCaption from '@/modules/model/components/objects/object-caption.vue'
import ModelObjectDeleteDialog from '@/modules/model/components/objects/object-delete-dialog.vue'
import ModelObjectEditableBy from '@/modules/model/components/objects/object-editable-by.vue'
import ModelObjectExpand from '@/modules/model/components/objects/object-expand.vue'
import ModelObjectExternal from '@/modules/model/components/objects/object-external.vue'
import ModelObjectName from '@/modules/model/components/objects/object-name.vue'
import ModelObjectParent from '@/modules/model/components/objects/object-parent.vue'
import ModelObjectParentUpdateDialog from '@/modules/model/components/objects/object-parent-update-dialog.vue'
import ModelObjectPreviewList from '@/modules/model/components/objects/object-preview-list.vue'
import ModelObjectType from '@/modules/model/components/objects/object-type.vue'
import ModelObjectTypeUpdateDialog from '@/modules/model/components/objects/object-type-update-dialog.vue'
import ModelStatus from '@/modules/model/components/status.vue'
import ModelTechnologyList from '@/modules/model/components/technology-list/index.vue'
import { ModelModule } from '@/modules/model/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { SocketModule } from '@/modules/socket/store'
import TagDeleteDialog from '@/modules/tag/components/delete-dialog.vue'
import TagGroupDeleteDialog from '@/modules/tag/components/group/delete-dialog.vue'
import TagPicker from '@/modules/tag/components/tag-picker/index.vue'
import { TagModule } from '@/modules/tag/store'
import TeamPicker from '@/modules/team/components/picker.vue'
import { TeamModule } from '@/modules/team/store'
import UserGoalTooltip from '@/modules/user/components/goal-tooltip.vue'
import * as userAnalytics from '@/modules/user/helpers/analytics'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'
import * as router from '@/plugins/router'

import BackButton from '../components/back-button.vue'
import DescriptionDialog from '../components/description-dialog.vue'
import DescriptionEditor from '../components/description-editor.vue'
import ForwardButton from '../components/forward-button.vue'
import HelpDialog from '../components/help-dialog.vue'
import OverlayBar from '../components/overlay/bar.vue'
import Toolbar from '../components/toolbar.vue'
import * as analytics from '../helpers/analytics'
import getFallbackDiagram from '../helpers/fallback-diagram'
import { EditorModule } from '../store'

const LOCATION_INTERVAL = 10 * 1000
const TYPING_INTERVAL = 10 * 1000

const DRAWER_STORAGE_KEY = 'drawer'

@Component({
  components: {
    AccuracyHelpDialog,
    Animation,
    BackButton,
    CatalogTechnologyMenu,
    CommentThreadDialog,
    DescriptionDialog,
    DescriptionEditor,
    DiagramCameraControls,
    DiagramCanvas,
    DiagramDeleteDialog,
    DiagramGroupDeleteDialog,
    DiagramInfo,
    DiagramLostAlert,
    DiagramMenu,
    DiagramProposedMergeDialog,
    FlowCancelButton,
    FlowContextMenu,
    FlowDeleteDialog,
    FlowName,
    FlowPathPreview,
    FlowPicker,
    FlowStepControlBack,
    FlowStepControlNext,
    FlowStepControlRestart,
    FlowStepIndicator,
    FlowStepList,
    FlowStepPreview,
    FlowSubflow,
    FlowSubflowBack,
    ForwardButton,
    HelpDialog,
    HistoryCompact,
    ModelActionsMenu,
    ModelConnectionChange,
    ModelConnectionDeleteDialog,
    ModelConnectionDirection,
    ModelConnectionFlipDialog,
    ModelConnectionLabelPosition,
    ModelConnectionLower,
    ModelConnectionReassignDialog,
    ModelConnectionReceiver,
    ModelConnectionSender,
    ModelConnectionShape,
    ModelInDiagrams,
    ModelInFlows,
    ModelObjectCaption,
    ModelObjectDeleteDialog,
    ModelObjectDependenciesList,
    ModelObjectEditableBy,
    ModelObjectExpand,
    ModelObjectExternal,
    ModelObjectGroups,
    ModelObjectLinksList,
    ModelObjectName,
    ModelObjectParent,
    ModelObjectParentUpdateDialog,
    ModelObjectPreviewList,
    ModelObjectType,
    ModelObjectTypeUpdateDialog,
    ModelStatus,
    ModelTechnologyList,
    OrganizationUpgradeMenu,
    OverlayBar,
    Tabs,
    TagDeleteDialog,
    TagGroupDeleteDialog,
    TagPicker,
    TeamPicker,
    Toolbar,
    UserGoalTooltip
  },
  name: 'Editor'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  catalogModule = getModule(CatalogModule, this.$store)
  commentModule = getModule(CommentModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  socketModule = getModule(SocketModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly canvasRef!: DiagramCanvas
  @Ref() readonly diagramCameraControlsRef!: DiagramCameraControls
  @Ref() readonly modelObjectPreviewListRef!: ModelObjectPreviewList
  @Ref() readonly modelObjectTooltipRef?: UserGoalTooltip
  @Ref() readonly flowsTooltipRef?: UserGoalTooltip
  @Ref() readonly commentTooltipRef?: UserGoalTooltip
  @Ref() readonly overlayBarRef!: OverlayBar

  keydownListener!: (event: KeyboardEvent) => void

  iconUrlForTheme = iconUrlForTheme

  editorError = ''
  canvasError = ''
  initialLoad = true
  initialTracked = false
  editorLoaded = false
  height = window.innerHeight - 57
  windowWidth = 0
  undoing = false
  fromVersion = false
  scaleValue: number | null = null

  editingExpiryTimer?: number
  locationInterval?: number
  typingInterval?: number

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return 'latest'
  }

  get dataLoaded () {
    const commentsSubscriptionStatus = this.commentModule.commentsSubscriptionStatus
    const connectionsSubscriptionStatus = this.modelModule.connectionsSubscriptionStatus
    const diagramsSubscriptionStatus = this.diagramModule.diagramsSubscriptionStatus
    const diagramContentsSubscriptionStatus = this.diagramModule.diagramContentsSubscriptionStatus
    const diagramGroupsSubscriptionStatus = this.diagramModule.diagramGroupsSubscriptionStatus
    const domainsSubscriptionStatus = this.domainModule.domainsSubscriptionStatus
    const flowsSubscriptionStatus = this.flowModule.flowsSubscriptionStatus
    const objectsSubscriptionStatus = this.modelModule.objectsSubscriptionStatus
    const tagGroupsSubscriptionStatus = this.tagModule.tagGroupsSubscriptionStatus
    const tagsSubscriptionStatus = this.tagModule.tagsSubscriptionStatus

    return (
      this.organizationModule.organizationsListStatus.success &&
      this.organizationModule.organizationUsersListStatus.successInfo.organizationId === this.currentOrganizationId &&

      this.teamModule.teamsListStatus.successInfo.organizationId === this.currentOrganizationId &&

      (this.landscapeModule.landscapeSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId || !!this.landscapeModule.landscapeSubscriptionStatus.loadingInfo.reconnect) &&
      (this.versionModule.versionsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId || !!this.versionModule.versionsSubscriptionStatus.loadingInfo.reconnect) &&

      ((commentsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && commentsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!commentsSubscriptionStatus.loadingInfo.reconnect) &&
      ((connectionsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && connectionsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!connectionsSubscriptionStatus.loadingInfo.reconnect) &&
      ((diagramsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && diagramsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!diagramsSubscriptionStatus.loadingInfo.reconnect) &&
      ((diagramContentsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && diagramContentsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!diagramContentsSubscriptionStatus.loadingInfo.reconnect) &&
      ((diagramGroupsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && diagramGroupsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!diagramGroupsSubscriptionStatus.loadingInfo.reconnect) &&
      ((domainsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && domainsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!domainsSubscriptionStatus.loadingInfo.reconnect) &&
      ((flowsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && flowsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!flowsSubscriptionStatus.loadingInfo.reconnect) &&
      ((objectsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && objectsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!objectsSubscriptionStatus.loadingInfo.reconnect) &&
      ((tagGroupsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && tagGroupsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!tagGroupsSubscriptionStatus.loadingInfo.reconnect) &&
      ((tagsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && tagsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!tagsSubscriptionStatus.loadingInfo.reconnect)
    )
  }

  get loaded () {
    return (this.dataLoaded || this.travelling) && (this.editorLoaded || this.travelling) && this.editorModule.resourceLoaded
  }

  get error () {
    return this.editorError || this.canvasError || this.socketError
  }

  get socketError () {
    return this.socketModule.socketSubscriptionActive ? undefined : (this.socketModule.socketSubscriptionError || this.socketModule.socketError)
  }

  get exploring () {
    return this.diagramModule.exploring
  }

  get travelling () {
    return this.versionModule.travelling
  }

  get drawer () {
    return this.$queryValue('drawer')
  }

  get objectTab () {
    return this.$queryValue('object_tab')
  }

  get currentModelHandleId () {
    return this.$queryValue('model')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentFlowStepId () {
    return this.$queryValue('flow_step')
  }

  get currentFlowPathIds () {
    return this.$queryArray('flow_path')
  }

  get currentFlowParentHandleId () {
    return this.$queryArray('flow_parent')?.slice(-1)?.[0]
  }

  get currentObjectIds () {
    return this.$queryArray('object')
  }

  get currentConnectionIds () {
    return this.$queryArray('connection')
  }

  get modelObjectsMenu () {
    return this.$queryValue('model_objects_menu')
  }

  get overlayTab () {
    return this.$queryValue('overlay_tab')
  }

  get overlayGroupId () {
    return this.$queryValue('overlay_group')
  }

  get overlayIdsPinned () {
    return this.$queryArray('overlay_pin')
  }

  get overlayIdsHidden () {
    return this.$queryArray('overlay_hide')
  }

  get overlayIdsFocused () {
    return this.$queryArray('overlay_focus')
  }

  get drawerExpanded () {
    return !!this.currentFlow && this.drawer === 'expanded'
  }

  get drawerObjectVisible () {
    return !!this.currentModelObjectIds.length || !!this.currentModelConnectionIds.length
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramModelObject () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentModelHandleId)
  }

  get currentDiagramModelParent () {
    return this.currentDiagramModelObject?.parentId ? this.modelModule.objects[this.currentDiagramModelObject.parentId] : undefined
  }

  get currentOtherDiagrams () {
    const currentDiagramModelObject = this.currentDiagramModelObject
    return Object.values(this.diagramModule.diagrams).filter(o => !o.parentId && o.modelId === currentDiagramModelObject?.id)
  }

  get currentDiagramGroup () {
    return this.currentDiagram?.groupId ? this.diagramModule.diagramGroups[this.currentDiagram.groupId] : undefined
  }

  get currentFlows () {
    return Object.values(this.flowModule.flows).filter(o => o.diagramId === this.currentDiagram?.id).sort((a, b) => a.name.localeCompare(b.name))
  }

  get currentFlow () {
    return this.currentFlows.find(o => o.handleId === this.currentFlowHandleId)
  }

  get currentFlowStep () {
    return this.currentFlowStepId ? this.currentFlow?.steps[this.currentFlowStepId] : undefined
  }

  get currentFlowPathSteps () {
    return Object
      .values(this.currentFlow?.steps || {})
      .filter(o => o.type?.endsWith('-path') && o.index === this.currentFlowStep?.index)
      .sort(sort.pathIndex)
  }

  get currentFlowParent () {
    return Object.values(this.flowModule.flows).find(o => o.handleId === this.currentFlowParentHandleId)
  }

  get currentUserTypingObject () {
    return Object.values(this.editorModule.typing).find(o => o.id === this.currentModelObject?.id && o.userId !== this.userModule.user?.id)
  }

  get currentUserTypingConnection () {
    return Object.values(this.editorModule.typing).find(o => o.id === this.currentModelConnection?.id && o.userId !== this.userModule.user?.id)
  }

  get currentModelObjectIds () {
    return this.currentObjectIds.map(o => this.currentDiagramContent?.objects[o]?.modelId).filter((o): o is string => !!o)
  }

  get currentModelConnectionIds () {
    return this.currentConnectionIds.map(o => this.currentDiagramContent?.connections[o]?.modelId).filter((o): o is string => !!o)
  }

  get currentObject () {
    return this.currentObjectIds[0] ? this.currentDiagramContent?.objects[this.currentObjectIds[0]] : undefined
  }

  get currentConnections () {
    return this.currentConnectionIds.map(o => this.currentDiagramContent?.connections[o]).filter((o): o is DiagramConnection => !!o)
  }

  get currentConnection (): DiagramConnection | undefined {
    return this.currentConnections[0]
  }

  get currentConnectionLower () {
    const diagramConnection = this.currentModelConnection && this.currentDiagram ? this.currentModelConnection.diagrams[this.currentDiagram.id] : undefined
    return diagramConnection && this.currentModelConnection && (this.currentModelConnection?.originId !== diagramConnection.originModelId || this.currentModelConnection.targetId !== diagramConnection.targetModelId)
  }

  get currentModelObject () {
    return this.currentObject ? this.modelModule.objects[this.currentObject.modelId] : undefined
  }

  get currentModelConnection () {
    return this.currentConnection?.modelId ? this.modelModule.connections[this.currentConnection.modelId] : undefined
  }

  get currentModelObjects () {
    return this.currentModelObjectIds.map(o => this.modelModule.objects[o]).filter((o): o is ModelObject => !!o)
  }

  get currentModelTechnologies () {
    const technologies = [...this.currentModelObjects, ...this.currentModelConnections].reduce((p, c) => ({
      ...p,
      ...c.technologies
    }), {} as Record<string, ModelObjectTechnology | ModelConnectionTechnology>)
    return Object
      .values(technologies)
      .map(o => ({
        ...o,
        icon: iconUrlForTheme(o)
      }))
      .sort(sort.index)
  }

  get currentModelObjectsLinksCount () {
    return Object.keys(this.currentModelObject?.links || {}).length
  }

  get currentModelObjectsLinksValid () {
    return Object
      .values(this.currentModelObject?.links || {})
      .filter(o => 'status' in o && o.status === 'valid').length
  }

  get currentModelObjectsLinksInvalid () {
    return Object
      .values(this.currentModelObject?.links || {})
      .filter(o => 'status' in o && o.status === 'invalid').length
  }

  get currentModelConnections () {
    return this.currentModelConnectionIds.map(o => this.modelModule.connections[o]).filter((o): o is ModelConnection => !!o)
  }

  get currentModelConnectionOriginIds () {
    return this.currentModelConnections.map(o => this.modelModule.objects[o.originId]).filter(o => o)
  }

  get drawerObjectMode () {
    if (this.currentObjectIds.length + this.currentConnectionIds.length > 1) {
      return 'multiple'
    } else if (this.currentModelObjectIds.length === 1 && this.currentModelObject) {
      if (
        this.currentModelObject.name.includes('\n') ||
        ['system', 'group', 'app', 'store', 'component'].includes(this.currentModelObject.type)
      ) {
        return 'details-two-line'
      } else {
        return 'details-one-line'
      }
    } else if (this.currentModelConnectionIds.length === 1 && this.currentModelConnection) {
      return this.currentModelConnection.name.includes('\n') ? 'details-connection-double' : 'details-connection-single'
    }
  }

  get tagBarWidth (): number {
    if (this.drawerObjectVisible) {
      return this.windowWidth - 320 - 24 - 320 - 24
    } else {
      return this.windowWidth - 24 - 320 - 24
    }
  }

  get drawerWidth (): number {
    if (this.drawerExpanded) {
      return 320
    } else {
      return 0
    }
  }

  get drawerHeight (): number {
    if (this.drawerExpanded) {
      return this.height - 48 - 2
    } else if (this.currentFlow) {
      let height = 80
      if (this.currentFlowParent) {
        height += 32
      }
      if (this.currentFlowStep) {
        height += 52
      }
      return height
    } else {
      return 40
    }
  }

  get drawerObjectWidth (): number {
    if (this.drawerObjectVisible) {
      return 320
    } else {
      return 0
    }
  }

  get showObjectTab () {
    return this.currentObjectIds.length === 1 && !this.currentConnectionIds.length && !!this.currentObject
  }

  get objectTabs () {
    const tabs: ITab[] = [
      {
        id: 'details',
        text: 'Details',
        to: {
          query: this.$setQuery({
            object_tab: 'details'
          })
        }
      },
      {
        id: 'connections',
        text: 'Connections',
        to: {
          query: this.$setQuery({
            object_tab: 'connections'
          })
        }
      },
      {
        id: 'history',
        text: 'History',
        to: {
          query: this.$setQuery({
            object_tab: 'history'
          })
        }
      }
    ]
    return tabs
  }

  get toolbarSelection () {
    return this.editorModule.toolbarSelection
  }

  get objectLinksSyncStatus () {
    return this.modelModule.objectLinksSyncStatus
  }

  get pageTitle () {
    const sections: string[] = []
    if (this.currentDiagram) {
      sections.push(this.currentDiagram.name)
    }
    if (this.currentLandscape) {
      sections.push(this.currentLandscape.name)
    }
    return sections
  }

  @Watch('pageTitle')
  onPageTitleChanged (sections: string[]) {
    router.setTitle(sections)
  }

  @Watch('$route')
  onRouteChange () {
    const query: any = {}

    if (this.objectTab && !this.objectTabs.some(o => o.id === this.objectTab)) {
      query.object_tab = this.objectTabs[0].id
    }

    if (this.currentFlow) {
      let flowPathUnion: string[] = []
      let flowPathRemove: string[] = []

      const groupedPaths = Object
        .values(this.currentFlow.steps)
        .filter(o => o.type?.endsWith('-path'))
        .reduce<FlowStep[][]>((p, c) => {
          const existingGroup = p.find(o => c.index === o[0].index)
          if (existingGroup) {
            existingGroup.push(c)
            return p
          } else {
            return [...p, [c]]
          }
        }, [])
        .map(o => o.sort(sort.pathIndex))

      flowPathUnion = groupedPaths.filter(o => o.every(p => !this.currentFlowPathIds.includes(p.id))).map(o => o[0].id)
      flowPathRemove = this.currentFlowPathIds.filter(o => groupedPaths.every(g => g.every(s => s.id !== o)))

      if (flowPathUnion.length || flowPathRemove.length) {
        query.flow_path = {
          ...this.$unionQueryArray(...flowPathUnion),
          ...this.$removeQueryArray(...flowPathRemove)
        }
      }

      const currentFlowPathIds = [...this.currentFlowPathIds, ...flowPathUnion].filter(o => !flowPathRemove.includes(o))
      const currentFlowSteps = Object
        .values(this.currentFlow.steps)
        .filter(o => !o.type?.endsWith('-path') && (!o.pathId || currentFlowPathIds.includes(o.pathId)))
        .reduce<FlowStep[][]>((p, c) => {
          const existingGroup = p.find(o => c.index === o[0].index)
          if (existingGroup) {
            existingGroup.push(c)
            return p
          } else {
            return [...p, [c]]
          }
        }, [])
        .sort((a, b) => sort.index(a[0], b[0]))
        .map(o => o.sort(sort.pathIndex))
        .flat()

      if (currentFlowSteps.every(o => o.id !== this.currentFlowStepId)) {
        const currentStep = this.currentFlowStepId ? this.currentFlow.steps[this.currentFlowStepId] : undefined

        const nextPathStep = currentStep?.pathId ? currentFlowSteps.find(o => o.index === currentStep.index && o.pathId && o.pathIndex !== null && o.pathIndex === currentStep.pathIndex) : undefined
        const nextPathPreviousStep = currentStep?.pathId ? [...currentFlowSteps].reverse().find(o => o.index === currentStep.index && o.pathId && o.pathIndex !== null && currentStep.pathIndex !== null && o.pathIndex <= currentStep.pathIndex) : undefined
        const previousStep = currentStep ? [...currentFlowSteps].reverse().find(o => o.index <= currentStep.index) : undefined

        const fallbackStep = nextPathStep || nextPathPreviousStep || previousStep || currentFlowSteps[0]
        if (fallbackStep) {
          query.flow_step = fallbackStep.id
        }
      }
    }

    this.$replaceQuery(query)
  }

  @Watch('showObjectTab')
  onShowObjectTabChanged (newVal?: boolean, oldVal?: boolean) {
    setImmediate(() => {
      if (newVal !== oldVal) {
        const query: any = {}

        query.expanded_connection = undefined
        query.expanded_connection_tab = undefined

        if (newVal && !this.objectTab) {
          query.object_tab = this.objectTabs[0].id
        } else if (!newVal && this.objectTab) {
          query.object_tab = undefined
        }

        this.$replaceQuery(query)
      }
    })
  }

  @Watch('currentDiagram')
  onCurrentDiagramChanged (currentDiagram?: Diagram, prevDiagram?: Diagram) {
    this.diagramModule.setDiagramContentStagedId(currentDiagram?.status === 'draft' ? currentDiagram.id : null)

    if (this.initialTracked && this.editorLoaded && currentDiagram && currentDiagram.version > -1 && (currentDiagram.id !== prevDiagram?.id || prevDiagram?.version === -1)) {
      this.trackDiagramEvent(currentDiagram)
      this.locationUpdate()
    }

    if (prevDiagram && !currentDiagram && this.currentModelHandleId && !this.exploring && !this.undoing && this.dataLoaded) {
      const fallbackDiagram = getFallbackDiagram({
        diagram: this.currentDiagram,
        diagramGroupId: this.currentDiagramGroup?.id,
        diagramGroups: Object.values(this.diagramModule.diagramGroups),
        diagrams: Object.values(this.diagramModule.diagrams),
        model: this.currentDiagramModelObject,
        modelParent: this.currentDiagramModelParent
      })

      if (fallbackDiagram) {
        this.$replaceQuery({
          connection: undefined,
          diagram: fallbackDiagram.diagram.handleId,
          flow: undefined,
          flow_parent: undefined,
          flow_path: undefined,
          flow_step: undefined,
          model: fallbackDiagram.model.handleId,
          object: undefined,
          scale: undefined,
          x1: undefined,
          x2: undefined,
          y1: undefined,
          y2: undefined
        })
      } else {
        this.$router.push({
          name: 'diagrams',
          params: {
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          }
        })
      }
    }

    this.modelObjectTooltipRef?.updateDimensions()
  }

  @Watch('currentFlow')
  onCurrentFlowChanged (currentFlow?: Flow, prevFlow?: Flow) {
    if (this.initialTracked && this.editorLoaded && currentFlow && currentFlow.version >= 0 && (currentFlow.id !== prevFlow?.id || prevFlow?.version === -1)) {
      this.trackFlowEvent(currentFlow)
    }

    if (this.drawer === 'expanded') {
      this.flowsTooltipRef?.complete()
    }
  }

  @Watch('dataLoaded')
  onDataLoadedChanged (dataLoaded: boolean) {
    if (dataLoaded) {
      this.loadEditor()
    } else {
      this.editorLoaded = false
    }
  }

  @Watch('currentOrganizationId')
  onCurrentOrganizationIdChanged (currentOrganizationId?: string) {
    if (currentOrganizationId && this.organizationModule.organizationUsersListStatus.loadingInfo.organizationId !== currentOrganizationId && this.organizationModule.organizationUsersListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.organizationModule.organizationUsersList(currentOrganizationId)
    }
    if (currentOrganizationId && this.teamModule.teamsListStatus.loadingInfo.organizationId !== currentOrganizationId && this.teamModule.teamsListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.teamModule.teamsList(currentOrganizationId)
    }
  }

  @Watch('drawer')
  onDrawerChanged (drawer?: string | null) {
    sessionStorage.setItem(DRAWER_STORAGE_KEY, JSON.stringify(drawer))

    if (drawer === 'expanded') {
      this.flowsTooltipRef?.complete()
    }
  }

  created () {
    this.fromVersion = this.travelling

    this.resize()
  }

  mounted () {
    this.keydownListener = this.keydown.bind(this)
    document.addEventListener('keydown', this.keydownListener)

    router.setTitle(this.pageTitle)

    if (!this.editorModule.resourceLoaded) {
      this.editorModule.loadResources()
    }

    if (this.currentOrganizationId && this.organizationModule.organizationUsersListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.organizationModule.organizationUsersListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.organizationModule.organizationUsersList(this.currentOrganizationId)
    }
    if (this.currentOrganizationId && this.teamModule.teamsListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.teamModule.teamsListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.teamModule.teamsList(this.currentOrganizationId)
    }

    if (this.dataLoaded) {
      this.loadEditor()
    }

    this.resize()

    try {
      sessionStorage.setItem(DRAWER_STORAGE_KEY, JSON.stringify(this.drawer))
    } catch (err) {
      console.error(err)
    }
  }

  beforeDestroy () {
    this.trackLandscapeGroup()
  }

  destroyed () {
    this.diagramModule.setDiagramContentStagedId(null)

    clearInterval(this.locationInterval)
    clearInterval(this.typingInterval)
    clearInterval(this.editingExpiryTimer)

    document.removeEventListener('keydown', this.keydownListener)
  }

  async loadEditor () {
    try {
      let diagram: Diagram | undefined
      let diagramContent: DiagramContent | undefined
      let model: ModelObject | undefined
      let objectsIds: string[] | undefined
      let connectionIds: string[] | undefined
      let flowHandleId: string | undefined
      let overlayGroupId: string | undefined
      let overlayIdsHidden: string[] | undefined
      let overlayIdsPinned: string[] | undefined
      let overlayIdsFocused: string[] | undefined

      if (this.currentModelHandleId) {
        model = this.modelModule.objects[this.currentModelHandleId] || Object.values(this.modelModule.objects).find(o => o.handleId === this.currentModelHandleId)
      }
      if (this.currentDiagramHandleId) {
        diagram = this.diagramModule.diagrams[this.currentDiagramHandleId] || Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
        diagramContent = this.diagramModule.diagramContents[this.currentDiagramHandleId] || Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
      }
      if (!model) {
        model = (diagram ? this.modelModule.objects[diagram.modelId] : undefined) || Object.values(this.modelModule.objects).find(o => o.type === 'root')
      }
      if (!model) {
        throw new Error('Could not find entry model')
      }

      const explore = await this.diagramModule.diagramsExplore({
        diagramId: diagram?.id,
        landscapeId: this.currentLandscapeId,
        modelId: model.id,
        versionId: this.currentVersionId
      })
      if (!explore) {
        throw new Error('Could not find entry diagram')
      }

      diagram = explore.diagram
      diagramContent = explore.diagramContent

      this.diagramModule.diagramAction({
        action: this.initialLoad ? 'initial-load' : 'load',
        diagramId: diagram.id,
        landscapeId: this.currentLandscapeId,
        versionId: this.currentVersionId
      })

      const technologies = this.modelModule.technologies

      if (this.currentObjectIds) {
        objectsIds = Object
          .values(diagramContent.objects)
          .filter(o => this.currentObjectIds.includes(o.id) || this.currentObjectIds.includes(o.modelId))
          .map(o => o.id)
      }
      if (this.currentConnectionIds) {
        connectionIds = Object
          .values(diagramContent.connections)
          .filter(o => this.currentConnectionIds.includes(o.id) || (o.modelId && this.currentConnectionIds.includes(o.modelId)))
          .map(o => o.id)
      }
      if (this.currentFlowHandleId) {
        flowHandleId = this.flowModule.flows[this.currentFlowHandleId]?.handleId || Object.values(this.flowModule.flows).find(o => o.diagramId === diagram?.id && o.handleId === this.currentFlowHandleId)?.handleId
      }
      if (this.overlayTab === 'tags' && this.overlayGroupId) {
        overlayGroupId = this.tagModule.tagGroups[this.overlayGroupId]?.handleId || Object.values(this.tagModule.tagGroups).find(o => o.handleId === this.overlayGroupId)?.handleId
        if (!overlayGroupId) {
          overlayGroupId = Object.values(this.tagModule.tagGroups).reduce((p, c) => p.index > c.index ? c : p)?.handleId
        }
      } else if (this.overlayTab === 'technology' && this.overlayGroupId) {
        overlayGroupId = this.catalogModule.technologyGroups[this.overlayGroupId] || 'all'
      } else if (this.overlayTab === 'status' && this.overlayGroupId) {
        overlayGroupId = 'all'
      } else if (this.overlayTab === 'teams' && this.overlayGroupId) {
        overlayGroupId = 'all'
      }
      if (this.overlayIdsHidden.length) {
        overlayIdsHidden = [
          ...Object.values(this.tagModule.tags).filter(o => this.overlayIdsHidden.includes(o.handleId) || this.overlayIdsHidden.includes(o.id)).map(o => o.handleId),
          ...Object.values(technologies).filter(o => this.overlayIdsHidden.includes(o.id)).map(o => o.id),
          ...Object.values(modelStatuses).filter(o => this.overlayIdsHidden.includes(o.id)).map(o => o.id),
          ...this.teamModule.teams.filter(o => this.overlayIdsHidden.includes(o.id)).map(o => o.id)
        ]
      }
      if (this.overlayIdsPinned.length) {
        overlayIdsPinned = [
          ...Object.values(this.tagModule.tags).filter(o => this.overlayIdsPinned.includes(o.handleId) || this.overlayIdsPinned.includes(o.id)).map(o => o.handleId),
          ...Object.values(technologies).filter(o => this.overlayIdsPinned.includes(o.id)).map(o => o.id),
          ...Object.values(modelStatuses).filter(o => this.overlayIdsPinned.includes(o.id)).map(o => o.id),
          ...this.teamModule.teams.filter(o => this.overlayIdsPinned.includes(o.id)).map(o => o.id)
        ]
      }
      if (this.overlayIdsFocused.length) {
        overlayIdsFocused = [
          ...Object.values(this.tagModule.tags).filter(o => this.overlayIdsFocused.includes(o.handleId) || this.overlayIdsFocused.includes(o.id)).map(o => o.handleId),
          ...Object.values(technologies).filter(o => this.overlayIdsFocused.includes(o.id)).map(o => o.id),
          ...Object.values(modelStatuses).filter(o => this.overlayIdsFocused.includes(o.id)).map(o => o.id),
          ...this.teamModule.teams.filter(o => this.overlayIdsFocused.includes(o.id)).map(o => o.id)
        ]
      }

      this.diagramModule.setDiagramContentStagedId(diagram.status === 'draft' ? diagram.id : null)

      let drawer: string | undefined
      try {
        const drawerSession = sessionStorage.getItem(DRAWER_STORAGE_KEY)
        if (drawerSession === null) {
          drawer = this.drawer || undefined
        } else {
          drawer = JSON.parse(drawerSession) || undefined
        }
      } catch (err) {
        console.error(err)
      }

      await this.$replaceQuery({
        connection: connectionIds,
        diagram: diagram.handleId,
        drawer,
        flow: flowHandleId,
        model: model.handleId,
        object: objectsIds,
        object_tab: this.showObjectTab ? this.objectTab || 'details' : this.objectTab,
        overlay_focus: overlayIdsFocused?.length ? overlayIdsFocused : undefined,
        overlay_group: overlayGroupId,
        overlay_hide: overlayIdsHidden?.length ? overlayIdsHidden : undefined,
        overlay_pin: overlayIdsPinned?.length ? overlayIdsPinned : undefined,
        overlay_tab: this.overlayTab || 'tags'
      })

      this.editorError = ''
      this.editorLoaded = true
      this.initialLoad = false

      this.$nextTick(() => {
        this.resize()
      })

      if (!this.initialTracked) {
        this.initialTracked = true

        this.trackLandscapeGroup()
        if (this.currentDiagram) {
          this.trackDiagramEvent(this.currentDiagram)
        }
        if (this.currentFlow) {
          this.trackFlowEvent(this.currentFlow)
        }
      }

      clearInterval(this.locationInterval)
      this.locationInterval = window.setInterval(this.locationUpdate.bind(this), LOCATION_INTERVAL)
      this.locationUpdate()
    } catch (err: any) {
      this.editorError = err.message
      throw err
    }
  }

  trackDiagramEvent (diagram: Diagram) {
    if (this.currentOrganizationId && this.currentDiagram && this.currentDiagram?.version > -1) {
      analytics.editorDiagramScreen.track(this, {
        diagramType: diagram.type,
        landscapeId: [this.currentLandscapeId],
        organizationId: [this.currentOrganizationId]
      })

      this.diagramModule.diagramView({
        diagramId: diagram.id,
        landscapeId: this.currentLandscapeId,
        versionId: this.currentVersionId
      })
    }
  }

  trackFlowEvent (flow: Flow) {
    if (this.currentOrganizationId && this.currentDiagram && this.currentDiagram?.version > -1) {
      analytics.editorFlowScreen.track(this, {
        diagramType: this.currentDiagram.type,
        landscapeId: [this.currentLandscapeId],
        organizationId: [this.currentOrganizationId]
      })

      this.flowModule.flowView({
        flowId: flow.id,
        landscapeId: this.currentLandscapeId,
        versionId: this.currentVersionId
      })
    }
  }

  trackDrawerEvent (expanded = true) {
    if (this.currentOrganizationId && this.currentDiagram) {
      if (expanded) {
        analytics.editorDrawerExpand.track(this, {
          diagramType: this.currentDiagram.type,
          landscapeId: [this.currentLandscapeId],
          organizationId: [this.currentOrganizationId]
        })
      } else {
        analytics.editorDrawerCollapse.track(this, {
          diagramType: this.currentDiagram.type,
          landscapeId: [this.currentLandscapeId],
          organizationId: [this.currentOrganizationId]
        })
      }
    }
  }

  trackLandscapeGroup () {
    const comments = Object.values(this.commentModule.activeComments)
    const diagrams = Object.values(this.diagramModule.diagrams)
    const flows = Object.values(this.flowModule.flows)
    const steps = flows.map(o => Object.values(o.steps)).flat()
    const objects = Object.values(this.modelModule.objects)
    const connections = Object.values(this.modelModule.connections)
    const tags = Object.values(this.tagModule.tags)
    const tagGroups = Object.values(this.tagModule.tagGroups)

    landscapeAnalyticsGroup.set(this.currentLandscapeId, {
      appDiagramCount: diagrams.filter(o => o.type === 'app-diagram').length,
      commentIdeaActiveCount: comments.filter(o => o.body.type === 'idea' && o.body.status === 'active').length,
      commentInaccurateActiveCount: comments.filter(o => o.body.type === 'inaccurate' && o.body.status === 'open').length,
      commentQuestionOpenCount: comments.filter(o => o.body.type === 'question' && o.body.status === 'open').length,
      componentDiagramCount: diagrams.filter(o => o.type === 'component-diagram').length,
      contextDiagramCount: diagrams.filter(o => o.type === 'context-diagram').length,
      diagramCount: diagrams.length,
      flowCount: flows.length,
      flowStepCount: steps.length,
      flowStepOutgoingCount: steps.filter(o => o.type === 'outgoing').length,
      flowStepReplyCount: steps.filter(o => o.type === 'reply').length,
      flowStepSelfActionCount: steps.filter(o => o.type === 'self-action').length,
      modelConnectionCount: connections.length,
      modelConnectionTagCount: connections.reduce((a, b) => a + b.tagIds.length, 0),
      modelObjectActorCount: objects.filter(o => o.type === 'actor').length,
      modelObjectAppCount: objects.filter(o => o.type === 'app').length,
      modelObjectComponentCount: objects.filter(o => o.type === 'component').length,
      modelObjectCount: objects.filter(o => o.type !== 'root').length,
      modelObjectGroupCount: objects.filter(o => o.type === 'group').length,
      modelObjectLinksInvalidCount: objects.filter(o => !Object.keys(o.links).length && !Object.values(o.links).every(l => 'status' in l && l.status === 'valid')).length,
      modelObjectLinksUnlinkedCount: objects.filter(o => !Object.keys(o.links).length).length,
      modelObjectLinksValidCount: objects.filter(o => Object.keys(o.links).length && Object.values(o.links).every(l => 'status' in l && l.status === 'valid')).length,
      modelObjectStoreCount: objects.filter(o => o.type === 'store').length,
      modelObjectSystemCount: objects.filter(o => o.type === 'system').length,
      modelObjectTagCount: objects.reduce((a, b) => a + b.tagIds.length, 0),
      tagColors: tags.map(o => o.color),
      tagCount: tags.length,
      tagGroupCount: tagGroups.length,
      tagGroupIcons: tagGroups.map(o => o.icon)
    })
  }

  areModelObjectsProtected (...modelObjectsIds: (string | undefined)[]): boolean {
    if (this.currentLandscapePermission === 'admin') { return false }
    return modelObjectsIds
      .filter((o): o is string => !!o)
      .some(o => {
        const modelObject = this.modelModule.objects[o]
        return (
          modelObject &&
          modelObject.teamOnlyEditing &&
          !!modelObject.teamIds.length &&
          !this.teamModule.userTeams.some(o => modelObject.teamIds.includes(o.id))
        )
      })
  }

  async keydown (event: KeyboardEvent) {
    const metaKey = window.navigator.platform.toLowerCase().includes('mac') ? event.metaKey : event.ctrlKey

    if (event.key.toLowerCase() === 'z' && event.shiftKey && metaKey && this.currentLandscapePermission && this.currentLandscapePermission !== 'read') {
      const actionTask = this.editorModule.taskLists[this.editorModule.taskListsCursor - 1]
      if (actionTask && !this.undoing) {
        try {
          this.undoing = true

          this.editorModule.setTaskListCursor(-1)

          for (const task of actionTask.tasks) {
            this.editorModule.addToTaskQueue({
              func: await this.applyTask(task)
            })
          }

          if (this.currentLandscape) {
            analytics.editorActivityRedo.track(this, {
              editorTaskCount: actionTask.tasks.length,
              editorTaskTypes: actionTask.tasks.map(o => o.type),
              landscapeId: [this.currentLandscape.id],
              organizationId: [this.currentLandscape.organizationId]
            })
          }
        } finally {
          this.undoing = false
        }
      }
      event.preventDefault()
    } else if (event.key.toLowerCase() === 'z' && metaKey && this.currentLandscapePermission && this.currentLandscapePermission !== 'read') {
      const actionTask = this.editorModule.taskLists[this.editorModule.taskListsCursor]
      if (actionTask && !this.undoing) {
        try {
          this.undoing = true

          this.editorModule.setTaskListCursor(1)

          for (const task of actionTask.revertTasks) {
            this.editorModule.addToTaskQueue({
              func: await this.applyTask(task)
            })
          }

          if (this.currentLandscape) {
            analytics.editorActivityUndo.track(this, {
              editorTaskCount: actionTask.revertTasks.length,
              editorTaskTypes: actionTask.revertTasks.map(o => o.type),
              landscapeId: [this.currentLandscape.id],
              organizationId: [this.currentLandscape.organizationId]
            })
          }
        } finally {
          this.undoing = false
        }
      }
      event.preventDefault()
    }
  }

  async applyTask (task: Task) {
    const { currentLandscape, currentVersion } = this
    if (task.type === 'batch') {
      this.editorModule.addToTaskQueue({
        func: await Promise.all(task.tasks.map(o => this.applyTask(o)))
      })
    } else if (task.type === 'navigation' && task.route.name && (this.$route.name !== task.route.name || !isEqual(this.$route.params, task.route.params) || !isEqual(this.$route.query, task.route.query))) {
      await this.$router.replace({
        name: task.route.name,
        params: task.route.params,
        query: task.route.query
      })
    } else if (task.type === 'comment-create' && currentLandscape && currentVersion) {
      const { comment, commentUpsert } = this.commentModule.generateComment(currentLandscape.id, currentVersion.id, task.props, task.id)
      this.commentModule.setCommentVersion(comment)
      this.commentModule.setActiveCommentVersion(comment)
      return () => this.commentModule.commentUpsert({
        commentId: comment.id,
        landscapeId: currentLandscape.id,
        props: commentUpsert,
        versionId: currentVersion.id
      })
    } else if (task.type === 'comment-update' && currentLandscape && currentVersion) {
      const { comment, commentUpdate } = this.commentModule.generateCommentCommit(task.id, task.props)
      this.commentModule.setCommentVersion(comment)
      this.commentModule.setActiveCommentVersion(comment)
      return () => this.commentModule.commentUpdate({
        commentId: task.id,
        landscapeId: currentLandscape.id,
        props: commentUpdate,
        versionId: currentVersion.id
      })
    } else if (task.type === 'comment-delete' && currentLandscape && currentVersion) {
      this.commentModule.removeComment(task.id)
      this.commentModule.removeActiveComment(task.id)
      return () => this.commentModule.commentDelete({
        commentId: task.id,
        landscapeId: currentLandscape.id,
        versionId: currentVersion.id
      })
    } else if (task.type === 'diagram-create' && currentLandscape && currentVersion) {
      const { diagram, diagramUpsert } = this.diagramModule.generateDiagram(currentLandscape.id, currentVersion.id, task.props, task.id)
      const { diagramContent, diagramContentUpsert } = this.diagramModule.generateDiagramContent(currentLandscape.id, currentVersion.id, diagram, task.props)
      this.diagramModule.setDiagramVersion(diagram)
      this.diagramModule.setDiagramContentVersion(diagramContent)
      return () => this.diagramModule.diagramUpsert({
        diagramId: task.id,
        landscapeId: currentLandscape.id,
        props: {
          ...diagramUpsert,
          ...diagramContentUpsert
        },
        updateViewedAt: true,
        versionId: currentVersion.id
      })
    } else if (task.type === 'diagram-update' && currentLandscape && currentVersion) {
      const { diagram, diagramUpdate } = this.diagramModule.generateDiagramCommit(task.id, task.props)
      this.diagramModule.setDiagramVersion(diagram)
      return () => this.diagramModule.diagramUpdate({
        diagramId: task.id,
        landscapeId: currentLandscape.id,
        props: diagramUpdate,
        updateViewedAt: true,
        versionId: currentVersion.id
      })
    } else if (task.type === 'diagram-delete' && currentLandscape && currentVersion) {
      this.diagramModule.removeDiagram(task.id)
      this.diagramModule.removeDiagramContent(task.id)
      return () => this.diagramModule.diagramDelete({
        diagramId: task.id,
        landscapeId: currentLandscape.id,
        versionId: currentVersion.id
      })
    } else if (task.type === 'diagram-content-update' && currentLandscape && currentVersion) {
      const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(task.id, task.props)
      this.diagramModule.setDiagramContentVersion(diagramContent)
      this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
      return () => this.diagramModule.diagramContentUpdate({
        diagramId: task.id,
        landscapeId: currentLandscape.id,
        props: diagramContentUpdate,
        versionId: currentVersion.id
      })
    } else if (task.type === 'diagram-group-create' && currentLandscape && currentVersion) {
      const { diagramGroup, diagramGroupUpsert } = this.diagramModule.generateDiagramGroup(currentLandscape.id, currentVersion.id, task.props, task.id)
      this.diagramModule.setDiagramGroupVersion(diagramGroup)
      return () => this.diagramModule.diagramGroupUpsert({
        diagramGroupId: task.id,
        landscapeId: currentLandscape.id,
        props: diagramGroupUpsert,
        versionId: currentVersion.id
      })
    } else if (task.type === 'diagram-group-update' && currentLandscape && currentVersion) {
      const { diagramGroup, diagramGroupUpdate } = this.diagramModule.generateDiagramGroupCommit(task.id, task.props)
      this.diagramModule.setDiagramGroupVersion(diagramGroup)
      return () => this.diagramModule.diagramGroupUpdate({
        diagramGroupId: task.id,
        landscapeId: currentLandscape.id,
        props: diagramGroupUpdate,
        versionId: currentVersion.id
      })
    } else if (task.type === 'diagram-group-delete' && currentLandscape && currentVersion) {
      this.diagramModule.removeDiagramGroup(task.id)
      return () => this.diagramModule.diagramGroupDelete({
        diagramGroupId: task.id,
        landscapeId: currentLandscape.id,
        versionId: currentVersion.id
      })
    } else if (task.type === 'model-object-create' && currentLandscape && currentVersion) {
      const { object, objectUpsert } = this.modelModule.generateObject(currentLandscape.id, currentVersion.id, task.props, task.id)
      this.modelModule.setObjectVersion(object)
      return () => this.modelModule.objectUpsert({
        landscapeId: currentLandscape.id,
        objectId: object.id,
        props: objectUpsert,
        versionId: currentVersion.id
      })
    } else if (task.type === 'model-object-update' && currentLandscape && currentVersion) {
      const { object, objectUpdate } = this.modelModule.generateObjectCommit(task.id, task.props)
      this.modelModule.setObjectVersion(object)
      return () => this.modelModule.objectUpdate({
        landscapeId: currentLandscape.id,
        objectId: task.id,
        props: objectUpdate,
        versionId: currentVersion.id
      })
    } else if (task.type === 'model-object-delete' && currentLandscape && currentVersion) {
      this.modelModule.removeObject(task.id)
      return () => this.modelModule.objectDelete({
        landscapeId: currentLandscape.id,
        objectId: task.id,
        versionId: currentVersion.id
      })
    } else if (task.type === 'model-connection-create' && currentLandscape && currentVersion) {
      const { connection, connectionUpsert } = this.modelModule.generateConnection(currentLandscape.id, currentVersion.id, task.props, task.id)
      this.modelModule.setConnectionVersion(connection)
      return () => this.modelModule.connectionUpsert({
        connectionId: connection.id,
        landscapeId: currentLandscape.id,
        props: connectionUpsert,
        versionId: currentVersion.id
      })
    } else if (task.type === 'model-connection-update' && currentLandscape && currentVersion) {
      const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(task.id, task.props)
      this.modelModule.setConnectionVersion(connection)
      return () => this.modelModule.connectionUpdate({
        connectionId: task.id,
        landscapeId: currentLandscape.id,
        props: connectionUpdate,
        versionId: currentVersion.id
      })
    } else if (task.type === 'model-connection-delete' && currentLandscape && currentVersion) {
      this.modelModule.removeConnection(task.id)
      return () => this.modelModule.connectionDelete({
        connectionId: task.id,
        landscapeId: currentLandscape.id,
        versionId: currentVersion.id
      })
    } else if (task.type === 'flow-create' && currentLandscape && currentVersion) {
      const { flow, flowUpsert } = this.flowModule.generateFlow(currentLandscape.id, currentVersion.id, task.props, task.id)
      this.flowModule.setFlowVersion(flow)
      return () => this.flowModule.flowUpsert({
        flowId: flow.id,
        landscapeId: currentLandscape.id,
        props: flowUpsert,
        versionId: currentVersion.id
      })
    } else if (task.type === 'flow-update' && currentLandscape && currentVersion) {
      const { flow, flowUpdate } = this.flowModule.generateFlowCommit(task.id, task.props)
      this.flowModule.setFlowVersion(flow)
      return () => this.flowModule.flowUpdate({
        flowId: task.id,
        landscapeId: currentLandscape.id,
        props: flowUpdate,
        versionId: currentVersion.id
      })
    } else if (task.type === 'flow-delete' && currentLandscape && currentVersion) {
      this.flowModule.removeFlow(task.id)
      return () => this.flowModule.flowDelete({
        flowId: task.id,
        landscapeId: currentLandscape.id,
        versionId: currentVersion.id
      })
    } else if (task.type === 'tag-create' && currentLandscape && currentVersion) {
      const { tag, tagUpsert } = this.tagModule.generateTag(currentLandscape.id, currentVersion.id, task.props, task.id)
      this.tagModule.setTagVersion(tag)
      return () => this.tagModule.tagUpsert({
        landscapeId: currentLandscape.id,
        props: tagUpsert,
        tagId: tag.id,
        versionId: currentVersion.id
      })
    } else if (task.type === 'tag-update' && currentLandscape && currentVersion) {
      const { tag, tagUpdate } = this.tagModule.generateTagCommit(task.id, task.props)
      this.tagModule.setTagVersion(tag)
      return () => this.tagModule.tagUpdate({
        landscapeId: currentLandscape.id,
        props: tagUpdate,
        tagId: task.id,
        versionId: currentVersion.id
      })
    } else if (task.type === 'tag-delete' && currentLandscape && currentVersion) {
      this.tagModule.removeTag(task.id)
      return () => this.tagModule.tagDelete({
        landscapeId: currentLandscape.id,
        tagId: task.id,
        versionId: currentVersion.id
      })
    } else if (task.type === 'team-update' && currentLandscape && currentVersion) {
      const { team, teamUpdate } = this.teamModule.generateTeamCommit(task.id, task.props)
      this.teamModule.setTeam({ team })
      return () => this.teamModule.teamUpdate({
        organizationId: team.organizationId,
        teamId: team.id,
        update: {
          color: teamUpdate.color
        }
      })
    } else if (task.type === 'tag-group-create' && currentLandscape && currentVersion) {
      const { tagGroup, tagGroupUpsert } = this.tagModule.generateTagGroup(currentLandscape.id, currentVersion.id, task.props, task.id)
      this.tagModule.setTagGroupVersion(tagGroup)
      return () => this.tagModule.tagGroupUpsert({
        landscapeId: currentLandscape.id,
        props: tagGroupUpsert,
        tagGroupId: tagGroup.id,
        versionId: currentVersion.id
      })
    } else if (task.type === 'tag-group-update' && currentLandscape && currentVersion) {
      const { tagGroup, tagGroupUpdate } = this.tagModule.generateTagGroupCommit(task.id, task.props)
      this.tagModule.setTagGroupVersion(tagGroup)
      return () => this.tagModule.tagGroupUpdate({
        landscapeId: currentLandscape.id,
        props: tagGroupUpdate,
        tagGroupId: task.id,
        versionId: currentVersion.id
      })
    } else if (task.type === 'tag-group-delete' && currentLandscape && currentVersion) {
      this.tagModule.removeTagGroup(task.id)
      return () => this.tagModule.tagGroupDelete({
        landscapeId: currentLandscape.id,
        tagGroupId: task.id,
        versionId: currentVersion.id
      })
    }

    return () => Promise.resolve()
  }

  locationUpdate () {
    if (!this.currentDiagram || !this.userModule.user || !this.currentOrganization || !this.currentOrganization.userIds.includes(this.userModule.user.id)) {
      return
    }

    this.editorModule.editorLocationUpdate({
      landscapeId: this.currentDiagram.landscapeId,
      location: {
        diagramId: this.currentDiagram.id,
        versionId: this.currentVersionId
      }
    })
  }

  typingStart (id: string) {
    const landscapeId = this.currentLandscape?.id
    if (!landscapeId) {
      return
    }

    this.editorModule.editorTypingUpdate({
      landscapeId,
      typing: {
        id
      }
    })
    clearInterval(this.typingInterval)
    this.typingInterval = window.setInterval(() => {
      this.editorModule.editorTypingUpdate({
        landscapeId,
        typing: { id }
      })
    }, TYPING_INTERVAL)
  }

  typingEnd () {
    const landscapeId = this.currentLandscape?.id
    if (!landscapeId) {
      return
    }

    clearInterval(this.typingInterval)
    this.editorModule.editorTypingUpdate({
      landscapeId,
      typing: {}
    })
  }

  setToolbarSelection (tool: DiagramTool) {
    if (this.canvasRef.view) {
      this.canvasRef.view.tool = tool
      const { $el } = this.canvasRef as any
      $el.focus()
      this.editorModule.setToolbarSelection(tool)
    }
  }

  objectLinksSync () {
    if (this.currentModelObject) {
      this.modelModule.objectLinksSync({
        landscapeId: this.currentLandscapeId,
        objectId: this.currentModelObject.id,
        versionId: this.currentModelObject.versionId
      })
    }
  }

  objectCreatePlaceholder (type?: DiagramObjectType) {
    if (this.canvasRef.view) {
      this.editorModule.setToolbarSelection(type || 'add')

      this.canvasRef.view.app.view.focus()
      this.canvasRef.view.interactive = true
      this.canvasRef.view.transitionState(new Context.Diagram.State.CreateObject(type))
    }
  }

  objectCreateHover (type?: DiagramObjectType) {
    if (this.canvasRef.view) {
      this.editorModule.setToolbarSelection(type || 'add')

      this.canvasRef.view.app.view.focus()
      this.canvasRef.view.interactive = true
      this.canvasRef.view.transitionState(new Context.Diagram.State.CreateObjectHover(type))
    }
  }

  modelCreate (type: DiagramObjectType, shape: DiagramObjectShape, modelId: string) {
    this.canvasRef.view?.createObjectInCenter(type, shape, modelId)
    this.modelObjectTooltipRef?.complete()
  }

  modelDrag (type: DiagramObjectType, shape: DiagramObjectShape, modelId: string) {
    if (this.canvasRef.view) {
      this.canvasRef.view.app.view.focus()
      this.canvasRef.view.interactive = true
      this.canvasRef.view.transitionState(new Context.Diagram.State.CreateObjectExisting(type, shape, modelId))
      this.modelObjectTooltipRef?.complete()
    }
  }

  assignConnectionModel (connectionId: string) {
    if (this.canvasRef.view && this.canvasRef.view.state.name !== 'create-connection') {
      const connection = this.canvasRef.view.connections[connectionId]
      if (connection) {
        this.canvasRef.view.transitionState(new Context.Diagram.State.CreateConnection(connection))
      }
    }
  }

  showModelDeleteHelpSnackbar () {
    const modelDeleteHelpSnackbar = parseInt(localStorage.getItem('modelDeleteHelpSnackbar') || '0')
    if (modelDeleteHelpSnackbar < 3) {
      localStorage.setItem('modelDeleteHelpSnackbar', `${modelDeleteHelpSnackbar + 1}`)
      this.alertModule.pushAlert({
        color: 'success',
        message: 'Removed from diagram. Tip: you can still access it in your model objects!'
      })
    }
  }

  resize () {
    this.height = window.innerHeight - 56
    this.windowWidth = window.innerWidth

    this.canvasRef?.resize()
    this.modelObjectPreviewListRef?.resize()
    this.overlayBarRef?.resize()
  }

  userGoalVideoModelling () {
    userAnalytics.userGoalVideoOpen.track(this, {
      landscapeId: this.currentLandscape ? [this.currentLandscape.id] : [],
      organizationId: this.currentLandscape ? [this.currentLandscape.organizationId] : [],
      userGoalName: 'model-objects'
    })
  }
}
