import { Diagram, DiagramGroup, ModelObject } from '@icepanel/platform-api-client'

import * as sort from '@/helpers/sort'

export default ({
  diagrams,
  diagramGroups,
  model,
  modelParent,
  diagram,
  diagramGroupId
}: {
  diagrams: Diagram[],
  diagramGroups: DiagramGroup[]
  model?: ModelObject
  modelParent?: ModelObject
  diagram?: Diagram
  diagramGroupId?: string
}): { model: ModelObject, diagram: Diagram, diagramGroup: DiagramGroup | null } | undefined => {
  const currentDiagram = diagrams.find(o => o.id === diagram?.id)
  const currentDiagramGroup = diagramGroups.find(o => o.id === currentDiagram?.groupId) || null
  if (model && currentDiagram) {
    return {
      diagram: currentDiagram,
      diagramGroup: currentDiagramGroup,
      model
    }
  }

  const parentDiagram = diagram?.parentId ? diagrams.find(o => o.modelId === model?.id && o.id === diagram.parentId) : undefined
  const parentDiagramGroup = parentDiagram?.groupId ? diagramGroups.find(o => o.id === parentDiagram.groupId) || null : null
  if (model && parentDiagram) {
    return {
      diagram: parentDiagram,
      diagramGroup: parentDiagramGroup,
      model
    }
  }

  const currentDiagrams = diagrams.filter(o => o.modelId === model?.id && (diagramGroupId ? diagramGroupId === o.groupId : !o.groupId)).sort(sort.index)
  const nextDiagram = [...currentDiagrams].reverse().find(o => diagram?.index !== undefined && o.index <= diagram.index) || currentDiagrams[0]
  const nextDiagramGroup = nextDiagram?.groupId ? diagramGroups.find(o => o.id === nextDiagram.groupId) : null
  if (model && nextDiagram && nextDiagramGroup !== undefined) {
    return {
      diagram: nextDiagram,
      diagramGroup: nextDiagramGroup,
      model
    }
  }

  const anyDiagram = diagrams.filter(o => o.modelId === model?.id).sort(sort.index).find(o => o)
  const anyDiagramGroup = anyDiagram?.groupId ? diagramGroups.find(o => o.id === anyDiagram.groupId) : null
  if (model && anyDiagram && anyDiagramGroup !== undefined) {
    return {
      diagram: anyDiagram,
      diagramGroup: anyDiagramGroup,
      model
    }
  }

  const anyParentDiagram = diagrams.filter(o => o.modelId === modelParent?.id && !o.groupId).sort(sort.index).find(o => o)
  if (modelParent && anyParentDiagram) {
    return {
      diagram: anyParentDiagram,
      diagramGroup: null,
      model: modelParent
    }
  }

  const anyParentGroupDiagram = diagrams.filter(o => o.modelId === modelParent?.id && o.groupId).sort(sort.index).find(o => o)
  const anyParentGroupDiagramGroup = diagramGroups.find(o => o.id === anyParentGroupDiagram?.groupId)
  if (modelParent && anyParentGroupDiagram && anyParentGroupDiagramGroup) {
    return {
      diagram: anyParentGroupDiagram,
      diagramGroup: anyParentGroupDiagramGroup,
      model: modelParent
    }
  }
}
